import { GrMail,GrMapLocation,GrLinkedin,GrGoogle,GrFacebook,GrGlobe,GrInstagram,GrList} from 'react-icons/gr';
import {endpoint} from '../../utils/endpoint'
import './Footer.css'
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import { fbLink, igLink } from '../../utils/links';


const Footer = ({language, cur}) => { 
    const { theme } = useContext(ThemeContext);
    const {menu} = language

return(

<div className='Footer cwhite' style={{backgroundColor:theme.mode === 'light' ? '#323b43' : theme.bgCards}}>
<div className="footer-col1 col">
    <img src={`${endpoint}${process.env.PUBLIC_URL}assets/footer-dark-logo.svg`} alt="QB Media" />
</div>
<div className="footer-col2 col">
    <GrGlobe size={30} className="row-icon"/>
    

    <div style={{
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        marginBottom:"30px",

    }}>
        <div>
        <a href={igLink}><GrInstagram size={30}/></a>
        <a href={fbLink}><GrFacebook size={30}/></a>
        <a href="https://www.google.com/maps/place/קיובי+מדיה+-+קידום+עסקים+באינטרנט+-+QB+Media%E2%80%AD/@31.4062525,35.0818155,7z/data=!4m5!3m4!1s0x0:0xa4b37fc802f4c0ee!8m2!3d31.4062525!4d35.0818155"><GrGoogle size={30}/></a>
        <a href="https://www.linkedin.com/company/qbmedia/"><GrLinkedin size={30}/></a>
        <a href={`mailto:info@qbmedia.${cur === '₪' ? 'co.il' : '.co'}`}><GrMail size={30}/></a><br/>
        </div>
        {cur === '₪' ? <p>"ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה),<br/> התשע"א-2010 וחוק הגנת הצרכן, התשמ"א-1981</p> : ''}
    </div>
    
</div>
<div className="footer-col3 col">
    <GrList size={30} className="row-icon"/>
    <div className="span">
    {menu.map((item,i) => (
        <li style={{listStyleType:'none'}} key={i}><a href={`#${item[1]}`}>{item[0]}</a></li>
      ) )}
    </div>
</div>
<div className="footer-col4 col">
    <GrMapLocation size={30} className="row-icon"/>
    <div className="span">
        {cur === '₪' ? <p>ביר אלמכסור<br/>רח׳ אלביר 10 <br/>Digital Agency<br/><a href='tel:972529921100'>052-992-1100</a></p>: <p>Digital Agency<br/>online services<br/><a href='tel:971581818006'>058-1818-006</a></p>}
    </div>
</div>
<div className="footer-bottom" style={{backgroundColor:theme.mode === 'light' ? '#252B31' : "#171717"}}>© 2020 — 2024 <span> QB Media.</span> All Rights Reserved.</div>
</div>

)
}

export default Footer